import { Stack, Typography } from "@mui/material";
import { SummaryWithTotals, Summary } from "../../contexts/DataContext";
import { dashboardSettings, resultSummarySettings } from "../../utils/dashboardSettingsConstants";
import { useTranslation } from "react-i18next";

type Props = {
    bgColor: string;
    data: Summary | SummaryWithTotals;
    translationPrefix: string;
    columnSpacing: number;
    columnRightPadding: number;
    dataType: "employment" | "valueadded" | "emissions";
    isDashboard?: boolean;
    showInMillions?: boolean;
    asteriskText?: string;
};

export const ResultSummary = ({ bgColor, data, translationPrefix, dataType, isDashboard, showInMillions = false, asteriskText }: Props) => {
    const { t } = useTranslation();

    const dataWithTotals = "total" in data ? data : null;

    return (
        <Stack
            width="90%"
            direction="row"
            sx={{
                borderRadius: dashboardSettings.borderRadius,
                border: dashboardSettings.grayBorder,
            }}
            position="relative"
        >
            {/* This is the left part of the table with labels */}
            <Stack width="35%">
                {/* This is the top-left part of the table with labels */}
                <Stack
                    width="100%"
                    height={`${resultSummarySettings.cardTitleAndtotalHeight}px`}
                    justifyContent="space-around"
                    sx={{ backgroundColor: bgColor, borderRadius: "8px 0px 0px 0px" }}
                >
                    {showInMillions && (
                        <Typography fontSize={resultSummarySettings.fontSize} color={resultSummarySettings.titleText} ml={2}>
                            {t("table.inMUSD")}
                        </Typography>
                    )}
                </Stack>
                {/* This is the mid-left part of the table with labels */}
                <Stack
                    alignItems={"center"}
                    direction={"row"}
                    height={`${resultSummarySettings.cardHeight - resultSummarySettings.cardTitleAndtotalHeight * 2}px`}
                    sx={{
                        backgroundColor: bgColor,
                        color: resultSummarySettings.titleText,
                        borderTop: dashboardSettings.whiteBorder,
                    }}
                >
                    <Stack height={"85%"} direction="column" justifyContent={data.data.length < 3 && !isDashboard ? "space-around" : "space-between"}>
                        {data.data.map((d: any, i: number) => {
                            //Table categories
                            return (
                                <Typography key={i} fontSize={resultSummarySettings.fontSize} ml={2}>
                                    {t(translationPrefix + "." + d.title)}
                                </Typography>
                            );
                        })}
                    </Stack>
                </Stack>
                {/* This is the bottom-left part of the table with labels */}
                <Stack
                    height={`${resultSummarySettings.cardTitleAndtotalHeight}px`}
                    sx={{
                        backgroundColor: bgColor,
                        color: resultSummarySettings.titleText,
                        borderTop: dashboardSettings.whiteBorder,
                        borderBottom: dashboardSettings.whiteBorder,
                        borderRadius: "0px 0px 0px 8px",
                    }}
                    justifyContent="center"
                >
                    <Typography fontSize={resultSummarySettings.fontSize} color={resultSummarySettings.titleText} sx={{ backgroundColor: bgColor }} ml={2}>
                        {t("total")}
                    </Typography>
                </Stack>
                {/* Special text for GHG that floats just below the left part of the table */}
                {asteriskText && (
                    <Typography position={"absolute"} bottom={"-35px"} left={"10px"} fontSize={resultSummarySettings.fontSize} fontStyle={"italic"}>
                        {asteriskText}
                    </Typography>
                )}
            </Stack>
            {/* Data part of table */}
            <Stack width="65%">
                {/* Data part of table: Header row */}
                <Stack
                    direction="row"
                    alignItems="center"
                    textAlign="right"
                    px={1}
                    sx={{
                        backgroundColor: bgColor,
                        borderRadius: "0px 8px 0px 0px",
                        color: resultSummarySettings.titleText,
                    }}
                    height={`${resultSummarySettings.cardTitleAndtotalHeight}px`}
                >
                    {/* Show total column first */}
                    {dataType === "employment" && dataWithTotals && (
                        <Typography fontSize={resultSummarySettings.fontSize} width={100 / (data.columns.length + 1) + `%`}>
                            {t(translationPrefix + ".total")}
                        </Typography>
                    )}
                    {dataWithTotals &&
                        data.columns.map((c, i) => (
                            <Typography key={i} fontSize={resultSummarySettings.fontSize} width={100 / (data.columns.length + 1) + `%`}>
                                {t(translationPrefix + "." + c)}
                            </Typography>
                        ))}
                    {!dataWithTotals &&
                        data.columns.map((c, i) => (
                            <Typography key={i} fontSize={resultSummarySettings.fontSize} width={100 / data.columns.length + `%`}>
                                {t(translationPrefix + "." + c)}
                            </Typography>
                        ))}
                    {!(dataType === "employment") && dataWithTotals && (
                        <Typography fontSize={resultSummarySettings.fontSize} width={100 / (data.columns.length + 1) + `%`}>
                            {t(translationPrefix + ".total")}
                        </Typography>
                    )}
                </Stack>
                {/* Data part of table: Data rows */}
                <Stack
                    alignItems={"center"}
                    direction={"row"}
                    height={`${resultSummarySettings.cardHeight - resultSummarySettings.cardTitleAndtotalHeight * 2}px`}
                    sx={{
                        borderTop: dashboardSettings.grayBorder,
                        borderBottom: dashboardSettings.grayBorder,
                    }}
                >
                    <Stack height={"85%"} width="100%" justifyContent={data.data.length < 3 && !isDashboard ? "space-around" : "space-between"} px={1}>
                        {/* Table Values */}
                        {data.data.map((d, i: number) => {
                            const values = [...d.values];
                            if ("total" in d) {
                                //This is for employment table total column order
                                dataType === "employment" ? values.unshift(d.total) : values.push(d.total);
                            }
                            return (
                                <Stack key={i} width="100%" direction="row" borderBottom={i !== data.data.length - 1 ? "solid 1px #e9e9e9" : ""}>
                                    {values.map((v: number | undefined, j: number) => {
                                        return (
                                            <Stack key={j} width={100 / data.columns.length + `%`} textAlign="right">
                                                <Typography key={j} fontSize={resultSummarySettings.fontSize}>
                                                    {v ? t("intlNumber", { val: showInMillions ? (v / 1000000).toFixed(2) : v.toFixed(0) }) : "n/a"}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                                </Stack>
                            );
                        })}
                    </Stack>
                </Stack>
                {/* Data part of table: Bottom row */}
                <Stack direction="row" height={`${resultSummarySettings.cardTitleAndtotalHeight}px`} alignItems="center" textAlign="right" px={1} sx={{}}>
                    {dataWithTotals && dataType === "employment" && (
                        <Typography fontSize={resultSummarySettings.fontSize} width={100 / (isDashboard ? data.columns.length : data.columns.length + 1) + `%`}>
                            {dataWithTotals.total === undefined
                                ? "n/a"
                                : t("intlNumber", { val: showInMillions ? (dataWithTotals.total / 1000000).toFixed(2) : dataWithTotals.total.toFixed(0) })}
                        </Typography>
                    )}
                    {data.columnTotals.map((tb: number | undefined, i: number) => {
                        return (
                            <Typography
                                key={i}
                                fontSize={resultSummarySettings.fontSize}
                                width={100 / (isDashboard ? data.columns.length : data.columns.length + 1) + `%`}
                            >
                                {tb ? t("intlNumber", { val: showInMillions ? (tb / 1000000).toFixed(2) : tb.toFixed(0) }) : "n/a"}
                            </Typography>
                        );
                    })}
                    {dataWithTotals && !(dataType === "employment") && (
                        <Typography fontSize={resultSummarySettings.fontSize} width={100 / (isDashboard ? data.columns.length : data.columns.length + 1) + `%`}>
                            {dataWithTotals.total === undefined
                                ? "n/a"
                                : t("intlNumber", { val: showInMillions ? (dataWithTotals.total / 1000000).toFixed(2) : dataWithTotals.total.toFixed(0) })}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
