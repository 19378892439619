// GHG these exist but are ignored
//Absolute emissions Other - CO2
//Absolute emissions Other - Non-CO2
//Emission removals
export enum SubIndicatorsEmission {
    Scope1CO2 = "Absolute emissions Scope 1 - CO2",
    Scope2CO2 = "Absolute emissions Scope 2 - CO2",
    Scope3CO2local = "Absolute emissions Scope 3 Local Upstream - CO2",
    Scope3CO2localdown = "Absolute emissions Scope 3 Local Downstream - CO2",
    Scope3CO2imports = "Absolute emissions Scope 3 Imports - CO2",
    Scope1nonCO2 = "Absolute emissions Scope 1 - Non-CO2",
    Scope2nonCO2 = "Absolute emissions Scope 2 - Non-CO2",
    Scope3nonCO2local = "Absolute emissions Scope 3 Local Upstream - Non-CO2",
    Scope3nonCO2localdown = "Absolute emissions Scope 3 Local Downstream - Non-CO2",
    Scope3nonCO2imports = "Absolute emissions Scope 3 Imports - Non-CO2",
}

// Value added
export enum SubIndicatorsVA {
    NetIncome = "Net Income",
    Taxes = "Taxes",
    Wages = "Wages",
}

// Empoyment
export enum SubIndicatorsEmployment {
    Female = "Female",
    Formal = "Formal",
    Informal = "Informal",
    Total = "Total",
}
