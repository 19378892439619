// TODO: Maybe there is a better name

import { WorkBook, WorkSheet, utils } from "xlsx";
// TODO: Move types from DataContext to this file
import {
    Data,
    ExcelJsonSheet,
    ExcelJsonRow,
    Filter,
    InvestmentDataType,
    RawData,
    Summary,
    SingleTableData,
    exampleData,
    GhgObjectData,
    EmploymentObjectData,
    ValueAddedObjectData,
    GeneralObjectData,
    AttributionObjectData,
    SummaryWithTotals,
    ObjectData,
    OverviewObjectData,
    AttributionFilterOption,
} from "../contexts/DataContext";

import { sheetNames } from "./sheetConstants";

import * as columns from "../utils/columnConstants";
import { SubIndicatorsEmission, SubIndicatorsEmployment, SubIndicatorsVA } from "../utils/subIndicators";
import { SubScope } from "../utils/subScopes";
import { Scope } from "./scopes";

export const processXlsx = (xlsx: WorkBook): RawData | null => {
    return {
        employment: processSheet(xlsx.Sheets[sheetNames.Employment]),
        ghg: processSheet(xlsx.Sheets[sheetNames.GHG]),
        valueAdded: processSheet(xlsx.Sheets[sheetNames.ValueAdded]),
        general: processSheet(xlsx.Sheets[sheetNames.General]),
        attribution: processSheet(xlsx.Sheets[sheetNames.Attribution]),
    };
};

const processRow = (row: { [key: string]: any }): ExcelJsonRow => {
    // for some reason some columns get a leading and trailing space added, so trim to prevent mismatching
    const trimmedKeysRow = Object.keys(row).reduce((acc: { [key: string]: any }, curr: string) => {
        // in the new object use the trimmed key, to read value use the non-trimed key
        acc[curr.trim()] = row[curr];
        return acc;
    }, {});
    const newRow = { ...trimmedKeysRow };
    for (const col of columns.Strings) {
        if (typeof trimmedKeysRow[col] === "string") {
            newRow[col] = trimmedKeysRow[col];
        }
        // also allow converting numbers to strings here
        else if (typeof trimmedKeysRow[col] === "number") {
            newRow[col] = "" + trimmedKeysRow[col];
        } else {
            newRow[col] = "";
        }
    }
    for (const col of columns.Numeric) {
        newRow[col] = typeof trimmedKeysRow[col] === "number" ? trimmedKeysRow[col] : undefined;
    }
    return newRow as ExcelJsonRow;
};

export const processSheet = (sheet: WorkSheet): ExcelJsonSheet => {
    const rawJsonSheet = utils.sheet_to_json<{ [key: string]: any }>(sheet);
    const jsonSheet: ExcelJsonSheet = rawJsonSheet.map(processRow);
    return jsonSheet;
};

export const filterData = (objectData: ObjectData, filter: Filter): ObjectData => {
    let filteredAttribution = objectData.attribution.filter((row) => row.year && filter.years?.includes(row.year));
    let filteredEmployment = objectData.employment.filter((row) => row.year && filter.years?.includes(row.year));
    let filteredGeneral = objectData.general.filter((row) => row.year && filter.years?.includes(row.year));
    let filteredGhg = objectData.ghg.filter((row) => row.year && filter.years?.includes(row.year));
    let filteredValueAdded = objectData.valueAdded.filter((row) => row.year && filter.years?.includes(row.year));

    // No need to filter or multiply on attribution === noFilter

    if (filter.attribution === "committed") {
        // filteredAttribution = filteredAttribution.filter((row) => row.committedShare !== undefined);
        filteredEmployment = filteredEmployment
            .filter((obj) => obj.committedShare !== undefined)
            .map((obj) => multiplyEmploymentWithShare(obj, filter.attribution));
        // No need to multiply general data with share
        filteredGeneral = filteredGeneral.filter((row) => row.committedShare !== undefined);
        filteredGhg = filteredGhg.filter((row) => row.committedShare !== undefined).map((obj) => multiplyGhgWithShare(obj, filter.attribution));
        filteredValueAdded = filteredValueAdded
            .filter((row) => row.committedShare !== undefined)
            .map((obj) => multiplyValueAddedWithShare(obj, filter.attribution));
    }

    if (filter.attribution === "outstanding") {
        //filteredAttribution = filteredAttribution.filter((row) => row.outstandingAmount !== undefined);
        filteredEmployment = filteredEmployment
            .filter((row) => row.outstandingAmount !== undefined)
            .map((obj) => multiplyEmploymentWithShare(obj, filter.attribution));
        // No need to multiply general data with share
        filteredGeneral = filteredGeneral.filter((row) => row.outstandingAmount !== undefined);
        filteredGhg = filteredGhg.filter((row) => row.outstandingAmount !== undefined).map((obj) => multiplyGhgWithShare(obj, filter.attribution));
        filteredValueAdded = filteredValueAdded
            .filter((row) => row.outstandingAmount !== undefined)
            .map((obj) => multiplyValueAddedWithShare(obj, filter.attribution));
    }

    return {
        attribution: filteredAttribution,
        general: filteredGeneral,
        ghg: filteredGhg,
        employment: filteredEmployment,
        valueAdded: filteredValueAdded,
    };
};

type ParsedRow = {
    id: string;
    client: string;
    investee: string;
    country: string;
    econAct: string;
    scope: Scope;
    subScope: SubScope;
    subIndicator: SubIndicatorsEmission | SubIndicatorsEmployment | SubIndicatorsVA;
    year: number | undefined;
    total: number | undefined;
    revenue: number | undefined;
    outstandingAmount: number | undefined;
    outstandingShare: number | undefined;
    committedShare: number | undefined;
};

/*
INvestmentdata = General ttab, just look at unique and sums of TOTAL, attribution sheet for INVESTMENT (exclude investees)
GHG scope 1+2 exclude 'FINANCE ENABLING' from sums! for scope3 include all finance enabling!

Overview page, Dark Medium colors only

Employment Summary:
First select TOTAL
Then split on Scope (Finance, Power, Backward)
ONly backward (Temp + perm) should be split on SubScope down into Suply/Direct/Induced

Total outstandingAmount = Employment sheet get SubIND == Total and SubScope=Dir and
Total outstandingAmount in general: NO investee take the 'Attribution - outstandingAmount amount' only for clients / FI

Tabel filter is just one of three: [Client, Country, EconAct]

In the table intensities are xtotal / total outstandingAmount

Empty or infinity should display n/a

Filtering on Attribution should multiply the total values by either committed or outstandingAmount share

*/

export const sumNumbersWithUndefined = (numbers: (number | undefined)[]): number | undefined => {
    const summableNumbers = numbers.filter((num) => num !== undefined) as number[];
    return summableNumbers.length > 0 ? summableNumbers.reduce((partialSum, a) => partialSum + a, 0) : undefined;
};

const perMilion = (num: number): number => {
    return num * 1_000_000;
};

const getParsedRows = (rows: ExcelJsonRow[]): ParsedRow[] => {
    // unique id based on Client, Investee, FiscalYear, Country, EconAct
    const parsedRows = rows.map((row) => ({
        id: [row[columns.Client], row[columns.Investee], row[columns.FiscalYear], row[columns.Country], row[columns.EconAct]].join("_"),
        client: row[columns.Client],
        investee: row[columns.Investee],
        country: row[columns.Country],
        econAct: row[columns.EconAct],
        scope: row[columns.Scope] as Scope, // perhaps find a more clean way to check this?
        subScope: row[columns.SubScope] as SubScope,
        subIndicator: row[columns.SubIndicator] as SubIndicatorsEmission | SubIndicatorsEmployment | SubIndicatorsVA,
        year: row[columns.FiscalYear],
        total: row[columns.Total],
        revenue: row[columns.Revenue],
        outstandingAmount: row[columns.AttributionOutstanding],
        outstandingShare: row[columns.AttributionShareOutstanding],
        committedShare: row[columns.AttributionShareCommitted],
    }));
    return parsedRows;
};

// create objects from excel rows based on 'unique id'

export const createObjectData = (rawData: RawData | null): ObjectData | null => {
    if (rawData) {
        const attributionRows = getParsedRows(rawData.attribution);
        const employmentRows = getParsedRows(rawData.employment);
        const generalRows = getParsedRows(rawData.general);
        const ghgRows = getParsedRows(rawData.ghg);
        const valueAddedRows = getParsedRows(rawData.valueAdded);
        return {
            attribution: createAttributionObjects(attributionRows),
            employment: createEmploymentObjects(employmentRows),
            general: createGeneralObjects(generalRows, ghgRows),
            ghg: createGhgObjects(ghgRows),
            valueAdded: createValueAddedObjects(valueAddedRows),
        };
    }
    return null;
};

const getGhgObjectForScope = (id: string, scope: Scope, rows: ParsedRow[]): GhgObjectData | undefined => {
    // stop if rows is empty
    if (rows.length === 0) {
        return undefined;
    }
    // available in each row, so pick the first
    const client = rows[0]?.client;
    const country = rows[0]?.country;
    const econAct = rows[0]?.econAct;
    const investee = rows[0]?.investee;

    const year = rows[0]?.year;
    const outstandingAmount = rows[0]?.outstandingAmount;
    const outstandingShare = rows[0]?.outstandingShare;
    const committedShare = rows[0]?.committedShare;

    // row specific, so find correct row
    const s1co2 = rows.find((row) => row.subIndicator === SubIndicatorsEmission.Scope1CO2)?.total;
    const s1nonco2 = rows.find((row) => row.subIndicator === SubIndicatorsEmission.Scope1nonCO2)?.total;

    const s2co2 = rows.find((row) => row.subIndicator === SubIndicatorsEmission.Scope2CO2)?.total;
    const s2nonco2 = rows.find((row) => row.subIndicator === SubIndicatorsEmission.Scope2nonCO2)?.total;

    const s3impco2 = rows.find((row) => row.subIndicator === SubIndicatorsEmission.Scope3CO2imports)?.total;
    const s3impnonco2 = rows.find((row) => row.subIndicator === SubIndicatorsEmission.Scope3nonCO2imports)?.total;
    const s3locco2 = rows.find((row) => row.subIndicator === SubIndicatorsEmission.Scope3CO2local)?.total;
    const s3locnonco2 = rows.find((row) => row.subIndicator === SubIndicatorsEmission.Scope3nonCO2local)?.total;

    return {
        id: id,
        client: client,
        country: country,
        econAct: econAct,
        investee: investee,
        scope: scope,
        year: year,
        outstandingAmount: outstandingAmount,
        outstandingShare: outstandingShare,
        committedShare: committedShare,
        scope1: { co2: s1co2, nonco2: s1nonco2, total: sumNumbersWithUndefined([s1co2, s1nonco2]) },
        scope2: { co2: s2co2, nonco2: s2nonco2, total: sumNumbersWithUndefined([s2co2, s2nonco2]) },
        scope3: {
            co2: sumNumbersWithUndefined([s3locco2, s3impco2]),
            nonco2: sumNumbersWithUndefined([s3locnonco2, s3impnonco2]),
            total: sumNumbersWithUndefined([s3locco2, s3impco2, s3locnonco2, s3impnonco2]),
        },
    };
};

const createGhgObjects = (rows: ParsedRow[]): GhgObjectData[] => {
    // convert multiple rows per client to a single object that has attributes
    const uniqueIds = Array.from(new Set(rows.map((row) => row.id)));
    const combinedObjects = uniqueIds.map((id) => {
        // There is never power enabling in ghg
        const backTemporaryRows = rows.filter((row) => row.id === id && row.scope === Scope.BackwardTemporary);
        const backPermanentRows = rows.filter((row) => row.id === id && row.scope === Scope.BackwardPermanent);
        const financeRows = rows.filter((row) => row.id === id && row.scope === Scope.FinanceEnabling);

        const backTempObj = getGhgObjectForScope(id, Scope.BackwardTemporary, backTemporaryRows);
        const backPermObj = getGhgObjectForScope(id, Scope.BackwardPermanent, backPermanentRows);
        const financeObj = getGhgObjectForScope(id, Scope.FinanceEnabling, financeRows);

        const result = [];
        if (backTempObj) {
            result.push(backTempObj);
        }
        if (backPermObj) {
            result.push(backPermObj);
        }
        if (financeObj) {
            result.push(financeObj);
        }
        return result;
    });
    const uniqueObjects = combinedObjects.reduce((prev, curr) => prev.concat(curr), []);
    return uniqueObjects;
};

const multiplyGhgWithShare = (obj: GhgObjectData, attributionFilter: AttributionFilterOption | null): GhgObjectData => {
    if (attributionFilter === null || attributionFilter === "noFilter") {
        return obj;
    }
    const share = attributionFilter === "committed" ? obj.committedShare : obj.outstandingShare;

    const s1co2 = obj.scope1.co2 && share ? obj.scope1.co2 * share : undefined;
    const s1nonco2 = obj.scope1.nonco2 && share ? obj.scope1.nonco2 * share : undefined;

    const s2co2 = obj.scope2.co2 && share ? obj.scope2.co2 * share : undefined;
    const s2nonco2 = obj.scope2.nonco2 && share ? obj.scope2.nonco2 * share : undefined;

    const s3co2 = obj.scope3.co2 && share ? obj.scope3.co2 * share : undefined;
    const s3nonco2 = obj.scope3.nonco2 && share ? obj.scope3.nonco2 * share : undefined;

    return {
        ...obj,
        scope1: { co2: s1co2, nonco2: s1nonco2, total: sumNumbersWithUndefined([s1co2, s1nonco2]) },
        scope2: { co2: s2co2, nonco2: s2nonco2, total: sumNumbersWithUndefined([s2co2, s2nonco2]) },
        scope3: { co2: s3co2, nonco2: s3nonco2, total: sumNumbersWithUndefined([s3co2, s3nonco2]) },
    };
};

const getEmploymentObjectForScope = (id: string, scope: Scope, rows: ParsedRow[]): EmploymentObjectData | undefined => {
    // stop if rows is empty
    if (rows.length === 0) {
        return undefined;
    }
    // available in each row, so pick the first
    const client = rows[0]?.client;
    const country = rows[0]?.country;
    const econAct = rows[0]?.econAct;
    const investee = rows[0]?.investee;

    const year = rows[0]?.year;
    const outstandingAmount = rows[0]?.outstandingAmount;
    const outstandingShare = rows[0]?.outstandingShare;
    const committedShare = rows[0]?.committedShare;

    // row specific, so find correct row
    const directRows = rows.filter((row) => row.subScope === SubScope.Direct);
    const supplyRows = rows.filter((row) => row.subScope === SubScope.SupplyChain);
    const inducedRows = rows.filter((row) => row.subScope === SubScope.Induced);
    const powerEnablingRows = rows.filter((row) => row.subScope === SubScope.PowerEnabling);

    const directTotal = directRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Total)?.total;
    const directFemale = directRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Female)?.total;
    const directFormal = directRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Formal)?.total;

    const supplyTotal = supplyRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Total)?.total;
    const supplyFemale = supplyRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Female)?.total;
    const supplyFormal = supplyRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Formal)?.total;

    const inducedTotal = inducedRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Total)?.total;
    const inducedFemale = inducedRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Female)?.total;
    const inducedFormal = inducedRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Formal)?.total;

    const powerTotal = powerEnablingRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Total)?.total;
    const powerFemale = powerEnablingRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Female)?.total;
    const powerFormal = powerEnablingRows.find((row) => row.subIndicator === SubIndicatorsEmployment.Formal)?.total;

    return {
        id: id,
        client: client,
        country: country,
        econAct: econAct,
        investee: investee,
        scope: scope,
        year: year,
        outstandingAmount: outstandingAmount,
        outstandingShare: outstandingShare,
        committedShare: committedShare,
        direct: { total: directTotal, female: directFemale, formal: directFormal },
        supply: { total: supplyTotal, female: supplyFemale, formal: supplyFormal },
        induced: { total: inducedTotal, female: inducedFemale, formal: inducedFormal },
        power: { total: powerTotal, female: powerFemale, formal: powerFormal },
        combined: {
            total: sumNumbersWithUndefined([directTotal, supplyTotal, inducedTotal, powerTotal]),
            female: sumNumbersWithUndefined([directFemale, supplyFemale, inducedFemale, powerFemale]),
            formal: sumNumbersWithUndefined([directFormal, supplyFormal, inducedFormal, powerFormal]),
        },
    };
};

const createEmploymentObjects = (rows: ParsedRow[]): EmploymentObjectData[] => {
    // convert multiple rows per client to a scope based objects that have attributes
    const uniqueIds = Array.from(new Set(rows.map((row) => row.id)));
    const combinedObjects = uniqueIds.map((id) => {
        const backTemporaryRows = rows.filter((row) => row.id === id && row.scope === Scope.BackwardTemporary);
        const backPermanentRows = rows.filter((row) => row.id === id && row.scope === Scope.BackwardPermanent);
        const financeRows = rows.filter((row) => row.id === id && row.scope === Scope.FinanceEnabling);
        const powerRows = rows.filter((row) => row.id === id && row.scope === Scope.PowerEnabling);

        const backTempObj = getEmploymentObjectForScope(id, Scope.BackwardTemporary, backTemporaryRows);
        const backPermObj = getEmploymentObjectForScope(id, Scope.BackwardPermanent, backPermanentRows);
        const financeObj = getEmploymentObjectForScope(id, Scope.FinanceEnabling, financeRows);
        const powerObj = getEmploymentObjectForScope(id, Scope.PowerEnabling, powerRows);

        const result = [];
        if (backTempObj) {
            result.push(backTempObj);
        }
        if (backPermObj) {
            result.push(backPermObj);
        }
        if (financeObj) {
            result.push(financeObj);
        }
        if (powerObj) {
            result.push(powerObj);
        }
        return result;
    });
    const uniqueObjects = combinedObjects.reduce((prev, curr) => prev.concat(curr), []);
    return uniqueObjects;
};

const multiplyEmploymentWithShare = (obj: EmploymentObjectData, attributionFilter: AttributionFilterOption | null): EmploymentObjectData => {
    if (attributionFilter === null || attributionFilter === "noFilter") {
        return obj;
    }
    const share = attributionFilter === "committed" ? obj.committedShare : obj.outstandingShare;

    const directTotal = obj.direct.total && share ? obj.direct.total * share : undefined;
    const directFemale = obj.direct.female && share ? obj.direct.female * share : undefined;
    const directFormal = obj.direct.formal && share ? obj.direct.formal * share : undefined;

    const supplyTotal = obj.supply.total && share ? obj.supply.total * share : undefined;
    const supplyFemale = obj.supply.female && share ? obj.supply.female * share : undefined;
    const supplyFormal = obj.supply.formal && share ? obj.supply.formal * share : undefined;

    const inducedTotal = obj.induced.total && share ? obj.induced.total * share : undefined;
    const inducedFemale = obj.induced.female && share ? obj.induced.female * share : undefined;
    const inducedFormal = obj.induced.formal && share ? obj.induced.formal * share : undefined;

    const powerTotal = obj.power.total && share ? obj.power.total * share : undefined;
    const powerFemale = obj.power.female && share ? obj.power.female * share : undefined;
    const powerFormal = obj.power.formal && share ? obj.power.formal * share : undefined;

    return {
        ...obj,
        direct: { total: directTotal, female: directFemale, formal: directFormal },
        supply: { total: supplyTotal, female: supplyFemale, formal: supplyFormal },
        induced: { total: inducedTotal, female: inducedFemale, formal: inducedFormal },
        combined: {
            total: sumNumbersWithUndefined([directTotal, supplyTotal, inducedTotal, powerTotal]),
            female: sumNumbersWithUndefined([directFemale, supplyFemale, inducedFemale, powerFemale]),
            formal: sumNumbersWithUndefined([directFormal, supplyFormal, inducedFormal, powerFormal]),
        },
    };
};

const createAttributionObjects = (rows: ParsedRow[]): AttributionObjectData[] => {
    // convert multiple rows per client to a single object that has attributes
    const uniqueIds = Array.from(new Set(rows.map((row) => row.id)));
    const uniqueObjects = uniqueIds.map((id) => {
        const filtered = rows.filter((row) => row.id === id);

        return {
            id: id,
            client: filtered[0]?.client || "<Unknown>",
            country: filtered[0]?.country || "<Unknown>",
            econAct: filtered[0]?.econAct || "<Unknown>",
            year: filtered[0]?.year,
            outstandingAmount: filtered[0]?.outstandingAmount,
            outstandingShare: filtered[0]?.outstandingShare,
            committedShare: filtered[0]?.committedShare,
        };
    });
    return uniqueObjects;
};

const createGeneralObjects = (generalRows: ParsedRow[], ghgRows: ParsedRow[]): GeneralObjectData[] => {
    // convert multiple rows per client to a single object that has attributes
    const uniqueIds = Array.from(new Set(generalRows.map((row) => row.id)));
    const uniqueObjects = uniqueIds.map((id) => {
        const filtered = generalRows.filter((row) => row.id === id);
        const ghgRow = ghgRows.find((row) => row.id === id);
        return {
            id: id,
            client: filtered[0]?.client || "<Unknown>",
            country: filtered[0]?.country || "<Unknown>",
            econAct: filtered[0]?.econAct || "<Unknown>",
            investee: filtered[0]?.investee,
            scope: filtered[0]?.scope,
            year: filtered[0]?.year,
            revenue: filtered[0]?.revenue,

            outstandingAmount: ghgRow?.outstandingAmount,
            outstandingShare: ghgRow?.outstandingShare,
            committedShare: ghgRow?.committedShare,
        };
    });
    return uniqueObjects;
};

const getValueAddedObjectForScope = (id: string, scope: Scope, rows: ParsedRow[]): ValueAddedObjectData | undefined => {
    // stop if rows is empty
    if (rows.length === 0) {
        return undefined;
    }
    // available in each row, so pick the first
    const client = rows[0]?.client;
    const country = rows[0]?.country;
    const econAct = rows[0]?.econAct;
    const investee = rows[0]?.investee;

    const year = rows[0]?.year;
    const outstandingAmount = rows[0]?.outstandingAmount;
    const outstandingShare = rows[0]?.outstandingShare;
    const committedShare = rows[0]?.committedShare;

    // row specific, so find correct row
    const directRows = rows.filter((row) => row.subScope === SubScope.Direct);
    const supplyRows = rows.filter((row) => row.subScope === SubScope.SupplyChain);
    const powerRows = rows.filter((row) => row.subScope === SubScope.PowerEnabling);

    const directNetIncome = directRows.find((row) => row.subIndicator === SubIndicatorsVA.NetIncome)?.total;
    const directWages = directRows.find((row) => row.subIndicator === SubIndicatorsVA.Wages)?.total;
    const directTaxes = directRows.find((row) => row.subIndicator === SubIndicatorsVA.Taxes)?.total;

    const supplyNetIncome = supplyRows.find((row) => row.subIndicator === SubIndicatorsVA.NetIncome)?.total;
    const supplyWages = supplyRows.find((row) => row.subIndicator === SubIndicatorsVA.Wages)?.total;
    const supplyTaxes = supplyRows.find((row) => row.subIndicator === SubIndicatorsVA.Taxes)?.total;

    const powerNetIncome = powerRows.find((row) => row.subIndicator === SubIndicatorsVA.NetIncome)?.total;
    const powerWages = powerRows.find((row) => row.subIndicator === SubIndicatorsVA.Wages)?.total;
    const powerTaxes = powerRows.find((row) => row.subIndicator === SubIndicatorsVA.Taxes)?.total;
    return {
        id: id,
        client: client,
        country: country,
        econAct: econAct,
        investee: investee,
        scope: scope,
        year: year,
        outstandingAmount: outstandingAmount,
        outstandingShare: outstandingShare,
        committedShare: committedShare,
        direct: { netIncome: directNetIncome, wages: directWages, taxes: directTaxes },
        supply: { netIncome: supplyNetIncome, wages: supplyWages, taxes: supplyTaxes },
        power: { netIncome: powerNetIncome, wages: powerWages, taxes: powerTaxes },
        combined: {
            netIncome: sumNumbersWithUndefined([directNetIncome, supplyNetIncome, powerNetIncome]),
            wages: sumNumbersWithUndefined([directWages, supplyWages, powerWages]),
            taxes: sumNumbersWithUndefined([directTaxes, supplyTaxes, powerTaxes]),
        },
    };
};

const createValueAddedObjects = (rows: ParsedRow[]): ValueAddedObjectData[] => {
    // convert multiple rows per client to a scope based objects that have attributes
    const uniqueIds = Array.from(new Set(rows.map((row) => row.id)));
    const combinedObjects = uniqueIds.map((id) => {
        const backTemporaryRows = rows.filter((row) => row.id === id && row.scope === Scope.BackwardTemporary);
        const backPermanentRows = rows.filter((row) => row.id === id && row.scope === Scope.BackwardPermanent);
        const financeRows = rows.filter((row) => row.id === id && row.scope === Scope.FinanceEnabling);
        const powerRows = rows.filter((row) => row.id === id && row.scope === Scope.PowerEnabling);

        const backTempObj = getValueAddedObjectForScope(id, Scope.BackwardTemporary, backTemporaryRows);
        const backPermObj = getValueAddedObjectForScope(id, Scope.BackwardPermanent, backPermanentRows);
        const financeObj = getValueAddedObjectForScope(id, Scope.FinanceEnabling, financeRows);
        const powerObj = getValueAddedObjectForScope(id, Scope.PowerEnabling, powerRows);

        const result = [];
        if (backTempObj) {
            result.push(backTempObj);
        }
        if (backPermObj) {
            result.push(backPermObj);
        }
        if (financeObj) {
            result.push(financeObj);
        }
        if (powerObj) {
            result.push(powerObj);
        }
        return result;
    });
    const uniqueObjects = combinedObjects.reduce((prev, curr) => prev.concat(curr), []);
    return uniqueObjects;
};

const multiplyValueAddedWithShare = (obj: ValueAddedObjectData, attributionFilter: AttributionFilterOption | null): ValueAddedObjectData => {
    if (attributionFilter === null || attributionFilter === "noFilter") {
        return obj;
    }
    const share = attributionFilter === "committed" ? obj.committedShare : obj.outstandingShare;

    const directNetIncome = obj.direct.netIncome && share ? obj.direct.netIncome * share : undefined;
    const directWages = obj.direct.wages && share ? obj.direct.wages * share : undefined;
    const directTaxes = obj.direct.taxes && share ? obj.direct.taxes * share : undefined;

    const supplyNetIncome = obj.supply.netIncome && share ? obj.supply.netIncome * share : undefined;
    const supplyWages = obj.supply.wages && share ? obj.supply.wages * share : undefined;
    const supplyTaxes = obj.supply.taxes && share ? obj.supply.taxes * share : undefined;

    const powerNetIncome = obj.power.netIncome && share ? obj.power.netIncome * share : undefined;
    const powerWages = obj.power.wages && share ? obj.power.wages * share : undefined;
    const powerTaxes = obj.power.taxes && share ? obj.power.taxes * share : undefined;

    return {
        ...obj,
        direct: { netIncome: directNetIncome, wages: directWages, taxes: directTaxes },
        supply: { netIncome: supplyNetIncome, wages: supplyWages, taxes: supplyTaxes },
        power: { netIncome: powerNetIncome, wages: powerWages, taxes: powerTaxes },
        combined: {
            netIncome: sumNumbersWithUndefined([directNetIncome, supplyNetIncome, powerNetIncome]),
            wages: sumNumbersWithUndefined([directWages, supplyWages, powerWages]),
            taxes: sumNumbersWithUndefined([directTaxes, supplyTaxes, powerTaxes]),
        },
    };
};

// create data for big tables at bottom

const makeOverviewObjectForScope = (
    id: string,
    scope: Scope,
    ghg: GhgObjectData[],
    employment: EmploymentObjectData[],
    valueAdded: ValueAddedObjectData[],
): OverviewObjectData | undefined => {
    const ghgObj = ghg.find((row) => row.id === id && row.scope === scope);
    const employmentObj = employment.find((row) => row.id === id && row.scope === scope);
    const valueAddedObj = valueAdded.find((row) => row.id === id && row.scope === scope);
    if (ghgObj || employmentObj || valueAddedObj) {
        return {
            client: ghgObj?.client || employmentObj?.client || valueAddedObj?.client || "<Unknown>",
            country: ghgObj?.country || employmentObj?.country || valueAddedObj?.country || "<Unknown>",
            econAct: ghgObj?.econAct || employmentObj?.econAct || valueAddedObj?.econAct || "<Unknown>",
            investee: ghgObj?.investee || employmentObj?.investee || valueAddedObj?.investee || undefined,
            outstandingAmount: ghgObj?.outstandingAmount || employmentObj?.outstandingAmount || valueAddedObj?.outstandingAmount || undefined,
            ghg: ghgObj,
            employment: employmentObj,
            valueAdded: valueAddedObj,
        };
    }
    return undefined;
};

const createOverviewTableData = (
    ghg: GhgObjectData[],
    employment: EmploymentObjectData[],
    valueAdded: ValueAddedObjectData[],
    attributionFilter: AttributionFilterOption | null,
): SingleTableData => {
    const uniqueIds = Array.from(new Set([...employment.map((obj) => obj.id), ...ghg.map((obj) => obj.id), ...valueAdded.map((obj) => obj.id)]));
    const combinedObjects = uniqueIds.map((id) => {
        const temp = makeOverviewObjectForScope(id, Scope.BackwardTemporary, ghg, employment, valueAdded);
        const perm = makeOverviewObjectForScope(id, Scope.BackwardPermanent, ghg, employment, valueAdded);
        const finance = makeOverviewObjectForScope(id, Scope.FinanceEnabling, ghg, employment, valueAdded);
        const power = makeOverviewObjectForScope(id, Scope.PowerEnabling, ghg, employment, valueAdded);

        const investee = temp?.investee || perm?.investee || finance?.investee || power?.investee || undefined;
        const outstandingAmount = temp?.outstandingAmount || perm?.outstandingAmount || finance?.outstandingAmount || power?.outstandingAmount || undefined;

        // ignore finance enabling scope1+2
        const scope12 = sumNumbersWithUndefined([
            temp?.ghg?.scope1.total,
            temp?.ghg?.scope2.total,
            perm?.ghg?.scope1.total,
            perm?.ghg?.scope2.total,
            power?.ghg?.scope1.total,
            power?.ghg?.scope2.total,
        ]);
        // include finance enabling scope1+2
        const scope123 = sumNumbersWithUndefined([
            scope12,
            finance?.ghg?.scope1.total,
            finance?.ghg?.scope2.total,
            finance?.ghg?.scope3.total,
            temp?.ghg?.scope3.total,
            perm?.ghg?.scope3.total,
            power?.ghg?.scope3.total,
        ]);

        const ghgIntensity = scope12 && outstandingAmount && !finance && attributionFilter !== "noFilter" ? perMilion(scope12 / outstandingAmount) : undefined;

        const employmentTotal = sumNumbersWithUndefined([
            temp?.employment?.combined.total,
            perm?.employment?.combined.total,
            finance?.employment?.combined.total,
            power?.employment?.combined.total,
        ]);

        const employmentIntensity =
            employmentTotal && outstandingAmount && !finance && attributionFilter !== "noFilter" ? perMilion(employmentTotal / outstandingAmount) : undefined;

        const valueAddedTotal = sumNumbersWithUndefined([
            temp?.valueAdded?.combined.netIncome,
            temp?.valueAdded?.combined.wages,
            temp?.valueAdded?.combined.taxes,
            perm?.valueAdded?.combined.netIncome,
            perm?.valueAdded?.combined.wages,
            perm?.valueAdded?.combined.taxes,
            finance?.valueAdded?.combined.netIncome,
            finance?.valueAdded?.combined.wages,
            finance?.valueAdded?.combined.taxes,
            power?.valueAdded?.combined.netIncome,
            power?.valueAdded?.combined.wages,
            power?.valueAdded?.combined.taxes,
        ]);

        const valueAddedIntensity =
            valueAddedTotal && outstandingAmount && !finance && attributionFilter !== "noFilter" ? perMilion(valueAddedTotal / outstandingAmount) : undefined;

        return [
            temp?.client || perm?.client || finance?.client || power?.client || "<Unknown>",
            temp?.country || perm?.country || finance?.country || power?.country || "<Unknown>",
            temp?.econAct || perm?.econAct || finance?.econAct || power?.econAct || "<Unknown>",
            //outstandingAmount if not finance enabling or investee
            investee || finance ? undefined : outstandingAmount,
            scope12,
            scope123,
            ghgIntensity,
            employmentTotal,
            employmentIntensity,
            valueAddedTotal,
            valueAddedIntensity,
        ];
    });
    return {
        columns: exampleData.overviewTableData.columns,
        data: combinedObjects,
    };
};

const createGhgTableData = (objects: GhgObjectData[], attributionFilter: AttributionFilterOption | null): SingleTableData => {
    return {
        // expected column order: TextKey, TotalAdvances musd, Scope1 em, Scope2 em, Scope3 em, Scope 1+2 em, Scope 1+2+3 em, Scope 1+2 intensity
        // Take only the NonFinanceEnabling here
        columns: exampleData.ghgTableData.columns,
        data: objects.map((obj) => {
            // exception for financeenabling scope3 = scope1+2+3 and no scope 1 and 2
            const isFinance = obj.scope === Scope.FinanceEnabling;
            const scope1 = isFinance ? undefined : obj.scope1.total;
            const scope2 = isFinance ? undefined : obj.scope2.total;
            const scope3 = isFinance ? sumNumbersWithUndefined([obj.scope1.total, obj.scope2.total, obj.scope3.total]) : obj.scope3.total;
            const scope12 = sumNumbersWithUndefined([scope1, scope2]);
            const scope123 = sumNumbersWithUndefined([scope1, scope2, scope3]);
            const intensity = scope12 && obj.outstandingAmount && attributionFilter !== "noFilter" ? perMilion(scope12 / obj.outstandingAmount) : undefined;
            // report outstandingAmount only if not investee and not finance enabling
            const outstandingAmount = obj.investee || isFinance ? undefined : obj.outstandingAmount;
            return [obj.client, obj.country, obj.econAct, outstandingAmount, scope1, scope2, scope3, scope12, scope123, intensity];
        }),
    };
};

const createEmploymentTableData = (objects: EmploymentObjectData[], attributionFilter: AttributionFilterOption | null): SingleTableData => {
    return {
        // expected column order: TextKey, TotalAdvances musd, Direct [Total, Female%], Supply [Total, Female%, Formal%],
        //                        Induced [Total, Female%, Formal%], PowerEnabling [Total], Employment intensities
        columns: exampleData.employmentTableData.columns,
        data: objects.map((obj) => {
            return [
                obj.client,
                obj.country,
                obj.econAct,
                // report outstandingAmount only if not investee and not finance enabling
                obj.investee || obj.scope === Scope.FinanceEnabling || obj.scope === Scope.PowerEnabling ? undefined : obj.outstandingAmount,
                // in the direct, supply and induced only show backwards temp or perm data
                obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.direct.total : undefined,
                obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.supply.total : undefined,
                obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.induced.total : undefined,
                // sum together direct, supply and induced for finance and power enabling
                obj.scope === Scope.PowerEnabling ? obj.combined.total : undefined,
                obj.scope === Scope.FinanceEnabling ? obj.combined.total : undefined,
                obj.combined.total && obj.outstandingAmount && attributionFilter !== "noFilter"
                    ? perMilion(obj.combined.total / obj.outstandingAmount)
                    : undefined,
            ];
        }),
    };
};

const createValueAddedTableData = (objects: ValueAddedObjectData[], attributionFilter: AttributionFilterOption | null): SingleTableData => {
    return {
        // expected column order: TextKey, TotalAdvances musd, Direct [NetIncome, Taxes, Wages, Total], Supply [NetIncome, Taxes, Wages, Total], Economic intensities
        columns: exampleData.valueAddedTableData.columns,
        data: objects.map((obj) => {
            const directTotal = sumNumbersWithUndefined([obj.direct.netIncome, obj.direct.wages, obj.direct.taxes]);
            const supplyTotal = sumNumbersWithUndefined([obj.supply.netIncome, obj.supply.wages, obj.supply.taxes]);
            const combinedTotal = sumNumbersWithUndefined([directTotal, supplyTotal]);
            return [
                obj.client,
                obj.country,
                obj.econAct,
                // report outstandingAmount only if not investee and not finance enabling
                obj.investee || obj.scope === Scope.FinanceEnabling || obj.scope === Scope.PowerEnabling ? undefined : obj.outstandingAmount,
                obj.direct.netIncome,
                obj.direct.taxes,
                obj.direct.wages,
                directTotal,
                obj.supply.netIncome,
                obj.supply.taxes,
                obj.supply.wages,
                supplyTotal,
                // currently does not include power enabling, not sure if needed?
                combinedTotal && obj.outstandingAmount && attributionFilter !== "noFilter" ? perMilion(combinedTotal / obj.outstandingAmount) : undefined,
            ];
        }),
    };
};

// create data for summaries

export const createInvestmentData = (generalObjects: GeneralObjectData[]): InvestmentDataType => {
    const clientCount = new Set(generalObjects.map((obj) => obj.client)).size;
    const countryCount = new Set(generalObjects.map((obj) => obj.country)).size;
    const revenueSum = sumNumbersWithUndefined(generalObjects.map((obj) => obj.revenue));
    // prevent multi counting of outstaning for clients with FI or Investees
    const outstandingSum = sumNumbersWithUndefined(
        generalObjects.map((obj) => (obj.investee || obj.scope === Scope.FinanceEnabling ? undefined : obj.outstandingAmount)),
    );
    return {
        clients: clientCount,
        countries: countryCount,
        totalRevenue: revenueSum,
        totalOutstanding: outstandingSum,
    };
};

const createGhgSummary = (objects: GhgObjectData[], attributionFilter: AttributionFilterOption | null): Summary => {
    // Expects two columns: Total amount (#) and intensity
    // for rows: Scope1, Scope2, Scope3
    // exception for financeenabling scope3 = scope1+2+3 and no scope 1 and 2
    const s1total = sumNumbersWithUndefined(objects.map((obj) => (obj.scope !== Scope.FinanceEnabling ? obj.scope1.total : undefined)));
    const s2total = sumNumbersWithUndefined(objects.map((obj) => (obj.scope !== Scope.FinanceEnabling ? obj.scope2.total : undefined)));
    const s3total = sumNumbersWithUndefined(
        objects.map((obj) =>
            obj.scope !== Scope.FinanceEnabling ? obj.scope3.total : sumNumbersWithUndefined([obj.scope1.total, obj.scope2.total, obj.scope3.total]),
        ),
    );
    const scopesTotal = sumNumbersWithUndefined([s1total, s2total, s3total]);
    const outstandingTotal = sumNumbersWithUndefined(
        objects.filter((obj) => obj.scope !== Scope.FinanceEnabling && !obj.investee).map((obj) => obj.outstandingAmount),
    );
    return {
        // these strings are translated in the UI element, only change if you update translation
        columns: ["emissionTotal", "emissionIntensity"],
        data: [
            {
                title: "scope1",
                values: [s1total, s1total && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(s1total / outstandingTotal) : undefined],
            },
            {
                title: "scope2",
                values: [s2total, s2total && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(s2total / outstandingTotal) : undefined],
            },
            {
                title: "scope3withAsterisk",
                values: [s3total, s3total && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(s3total / outstandingTotal) : undefined],
            },
        ],
        columnTotals: [
            scopesTotal,
            scopesTotal && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(scopesTotal / outstandingTotal) : undefined,
        ],
    };
};

const createEmploymentSummary = (objects: EmploymentObjectData[], attributionFilter: AttributionFilterOption | null) => {
    // Expects two columns: Total amount (#) and intensity
    // for rows: direct, finaceEnabling, induced, powerEnalbing, supplyChain
    const direct = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.direct.total : undefined)),
    );
    const induced = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.induced.total : undefined)),
    );
    const supplyChain = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.supply.total : undefined)),
    );
    const financeEnabling = sumNumbersWithUndefined(objects.map((obj) => (obj.scope === Scope.FinanceEnabling ? obj.combined.total : undefined)));
    const powerEnabling = sumNumbersWithUndefined(objects.map((obj) => (obj.scope === Scope.PowerEnabling ? obj.combined.total : undefined)));
    const employmentTotal = sumNumbersWithUndefined([direct, financeEnabling, induced, powerEnabling, supplyChain]);
    const outstandingTotal = sumNumbersWithUndefined(
        objects
            .filter((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary) && !obj.investee)
            .map((obj) => obj.outstandingAmount),
    );
    return {
        // these strings are translated in the UI element, only change if you update translation
        columns: ["employmentTotal", "employmentIntensity"],
        data: [
            {
                title: "direct",
                values: [direct, direct && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(direct / outstandingTotal) : undefined],
            },
            {
                title: "induced",
                values: [induced, induced && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(induced / outstandingTotal) : undefined],
            },
            {
                title: "supplyChain",
                values: [
                    supplyChain,
                    supplyChain && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(supplyChain / outstandingTotal) : undefined,
                ],
            },
            {
                title: "financeEnabling",
                values: [
                    financeEnabling,
                    financeEnabling && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(financeEnabling / outstandingTotal) : undefined,
                ],
            },
            {
                title: "powerEnabling",
                values: [
                    powerEnabling,
                    powerEnabling && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(powerEnabling / outstandingTotal) : undefined,
                ],
            },
        ],
        columnTotals: [
            employmentTotal,
            employmentTotal && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(employmentTotal / outstandingTotal) : undefined,
        ],
    };
};

const createValueAddedSummary = (objects: ValueAddedObjectData[], attributionFilter: AttributionFilterOption | null): Summary => {
    // Expects two columns: Total amount (#) and intensity
    // for rows: Net Income, Taxes, Wages
    const netIncome = sumNumbersWithUndefined(objects.map((obj) => sumNumbersWithUndefined([obj.direct.netIncome, obj.supply.netIncome, obj.power.netIncome])));
    const wages = sumNumbersWithUndefined(objects.map((obj) => sumNumbersWithUndefined([obj.direct.wages, obj.supply.wages, obj.power.wages])));
    const taxes = sumNumbersWithUndefined(objects.map((obj) => sumNumbersWithUndefined([obj.direct.taxes, obj.supply.taxes, obj.power.taxes])));
    const valueAddedTotal = sumNumbersWithUndefined([netIncome, wages, taxes]);
    const outstandingTotal = sumNumbersWithUndefined(
        objects.filter((obj) => obj.scope !== Scope.FinanceEnabling && !obj.investee).map((obj) => obj.outstandingAmount),
    );
    return {
        // these strings are translated in the UI element, only change if you update translation
        columns: ["economicTotal", "economicIntensity"],
        data: [
            {
                title: "wages",
                values: [wages, wages && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(wages / outstandingTotal) : undefined],
            },
            {
                title: "netIncome",
                values: [netIncome, netIncome && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(netIncome / outstandingTotal) : undefined],
            },
            {
                title: "taxes",
                values: [taxes, taxes && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(taxes / outstandingTotal) : undefined],
            },
        ],
        columnTotals: [
            valueAddedTotal,
            valueAddedTotal && outstandingTotal && attributionFilter !== "noFilter" ? perMilion(valueAddedTotal / outstandingTotal) : undefined,
        ],
    };
};

// create data for charts and tables per page

const createGhgIndividualClientsChartData = (objects: GhgObjectData[]): SummaryWithTotals => {
    // for rows: Scope1 [co2 / nonco2], Scope2 [co2 / nonco2], Scope3 [co2 / nonco2]
    const nonFinanceRows = objects.filter((obj) => obj.scope !== Scope.FinanceEnabling);

    const s1co2 = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.scope1.co2));
    const s2co2 = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.scope2.co2));
    const s3co2 = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.scope3.co2));
    const s1nonco2 = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.scope1.nonco2));
    const s2nonco2 = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.scope2.nonco2));
    const s3nonco2 = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.scope3.nonco2));
    return {
        columns: ["co2", "nonCo2"],
        data: [
            { title: "scope1", values: [s1co2, s1nonco2], total: sumNumbersWithUndefined([s1co2, s1nonco2]) },
            { title: "scope2", values: [s2co2, s2nonco2], total: sumNumbersWithUndefined([s2co2, s2nonco2]) },
            { title: "scope3", values: [s3co2, s3nonco2], total: sumNumbersWithUndefined([s3co2, s3nonco2]) },
        ],
        columnTotals: [sumNumbersWithUndefined([s1co2, s2co2, s3co2]), sumNumbersWithUndefined([s1nonco2, s2nonco2, s3nonco2])],
        total: sumNumbersWithUndefined([s1co2, s2co2, s3co2, s1nonco2, s2nonco2, s3nonco2]),
    };
};

const createGhgSectoralExposureChartData = (objects: GhgObjectData[]): SummaryWithTotals => {
    // should ONLY use Finance Enabling!
    const financeRows = objects.filter((obj) => obj.scope === Scope.FinanceEnabling);

    const s1co2 = sumNumbersWithUndefined(financeRows.map((obj) => obj.scope1.co2));
    const s2co2 = sumNumbersWithUndefined(financeRows.map((obj) => obj.scope2.co2));
    const s3co2 = sumNumbersWithUndefined(financeRows.map((obj) => obj.scope3.co2));
    const s1nonco2 = sumNumbersWithUndefined(financeRows.map((obj) => obj.scope1.nonco2));
    const s2nonco2 = sumNumbersWithUndefined(financeRows.map((obj) => obj.scope2.nonco2));
    const s3nonco2 = sumNumbersWithUndefined(financeRows.map((obj) => obj.scope3.nonco2));
    return {
        columns: ["co2", "nonCo2"],
        data: [
            { title: "scope1", values: [s1co2, s1nonco2], total: sumNumbersWithUndefined([s1co2, s1nonco2]) },
            { title: "scope2", values: [s2co2, s2nonco2], total: sumNumbersWithUndefined([s2co2, s2nonco2]) },
            { title: "scope3", values: [s3co2, s3nonco2], total: sumNumbersWithUndefined([s3co2, s3nonco2]) },
        ],
        columnTotals: [sumNumbersWithUndefined([s1co2, s2co2, s3co2]), sumNumbersWithUndefined([s1nonco2, s2nonco2, s3nonco2])],
        total: sumNumbersWithUndefined([s1co2, s2co2, s3co2, s1nonco2, s2nonco2, s3nonco2]),
    };
};

const createEmploymentIndividualClientsTableData = (objects: EmploymentObjectData[]): SummaryWithTotals => {
    const directTotal = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.direct.total : undefined)),
    );
    const directFemale = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.direct.female : undefined)),
    );
    const directFormal = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.direct.formal : undefined)),
    );
    const inducedTotal = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.induced.total : undefined)),
    );
    const inducedFemale = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.induced.female : undefined)),
    );
    const inducedFormal = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.induced.formal : undefined)),
    );
    const supplyTotal = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.supply.total : undefined)),
    );
    const supplyFemale = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.supply.female : undefined)),
    );
    const supplyFormal = sumNumbersWithUndefined(
        objects.map((obj) => (obj.scope === Scope.BackwardPermanent || obj.scope === Scope.BackwardTemporary ? obj.supply.formal : undefined)),
    );
    const powerTotal = sumNumbersWithUndefined(objects.map((obj) => (obj.scope === Scope.PowerEnabling ? obj.combined.total : undefined)));
    const powerFemale = sumNumbersWithUndefined(objects.map((obj) => (obj.scope === Scope.PowerEnabling ? obj.combined.female : undefined)));
    const powerFormal = sumNumbersWithUndefined(objects.map((obj) => (obj.scope === Scope.PowerEnabling ? obj.combined.formal : undefined)));

    return {
        columns: ["ofWhichFemale", "ofWhichFormal"],
        data: [
            { title: "direct", values: [directFemale, directFormal], total: directTotal },
            { title: "induced", values: [inducedFemale, inducedFormal], total: inducedTotal },
            { title: "supplyChain", values: [supplyFemale, supplyFormal], total: supplyTotal },
            { title: "powerEnabling", values: [powerFemale, powerFormal], total: powerTotal },
        ],
        columnTotals: [
            sumNumbersWithUndefined([directFemale, inducedFemale, supplyFemale, powerFemale]),
            sumNumbersWithUndefined([directFormal, inducedFormal, supplyFormal, powerFormal]),
        ],
        total: sumNumbersWithUndefined([directTotal, inducedTotal, supplyTotal, powerTotal]),
    };
};

const createEmploymentSectoralExposureTableData = (objects: EmploymentObjectData[]): SummaryWithTotals => {
    // only take finance enabling here
    const financeRows = objects.filter((obj) => obj.scope === Scope.FinanceEnabling);

    const directTotal = sumNumbersWithUndefined(financeRows.map((obj) => obj.direct.total));
    const directFemale = sumNumbersWithUndefined(financeRows.map((obj) => obj.direct.female));
    const directFormal = sumNumbersWithUndefined(financeRows.map((obj) => obj.direct.formal));
    const inducedTotal = sumNumbersWithUndefined(financeRows.map((obj) => obj.induced.total));
    const inducedFemale = sumNumbersWithUndefined(financeRows.map((obj) => obj.induced.female));
    const inducedFormal = sumNumbersWithUndefined(financeRows.map((obj) => obj.induced.formal));
    const supplyTotal = sumNumbersWithUndefined(financeRows.map((obj) => obj.supply.total));
    const supplyFemale = sumNumbersWithUndefined(financeRows.map((obj) => obj.supply.female));
    const supplyFormal = sumNumbersWithUndefined(financeRows.map((obj) => obj.supply.formal));

    return {
        columns: ["ofWhichFemale", "ofWhichFormal"],
        data: [
            { title: "direct", values: [directFemale, directFormal], total: directTotal },
            { title: "induced", values: [inducedFemale, inducedFormal], total: inducedTotal },
            { title: "supplyChain", values: [supplyFemale, supplyFormal], total: supplyTotal },
        ],
        columnTotals: [
            sumNumbersWithUndefined([directFemale, inducedFemale, supplyFemale]),
            sumNumbersWithUndefined([directFormal, inducedFormal, supplyFormal]),
        ],
        total: sumNumbersWithUndefined([directTotal, inducedTotal, supplyTotal]),
    };
};

const createEmploymentIndividualClientsChartData = (summary: SummaryWithTotals): SummaryWithTotals => {
    const direct = summary.data.find((d) => d.title === "direct")?.total ?? undefined;
    const induced = summary.data.find((d) => d.title === "induced")?.total ?? undefined;
    const supplyChain = summary.data.find((d) => d.title === "supplyChain")?.total ?? undefined;
    const powerEnabling = summary.data.find((d) => d.title === "powerEnabling")?.total ?? undefined;
    const total = sumNumbersWithUndefined([direct, induced, supplyChain, powerEnabling]);
    return {
        columns: ["clients"],
        data: [
            { title: "direct", values: [direct], total: direct },
            { title: "induced", values: [induced], total: induced },
            { title: "supplyChain", values: [supplyChain], total: supplyChain },
            { title: "powerEnabling", values: [powerEnabling], total: powerEnabling },
        ],
        columnTotals: [total],
        total: total,
    };
};

const createEmploymentSectoralExposureChartData = (summary: SummaryWithTotals): SummaryWithTotals => {
    const direct = summary.data.find((d) => d.title === "direct")?.total ?? undefined;
    const induced = summary.data.find((d) => d.title === "induced")?.total ?? undefined;
    const supplyChain = summary.data.find((d) => d.title === "supplyChain")?.total ?? undefined;
    const total = sumNumbersWithUndefined([direct, induced, supplyChain]);
    return {
        columns: ["clients"],
        data: [
            { title: "direct", values: [direct], total: direct },
            { title: "induced", values: [induced], total: induced },
            { title: "supplyChain", values: [supplyChain], total: supplyChain },
        ],
        columnTotals: [total],
        total: total,
    };
};

const createValueAddedIndividualClientsTableData = (objects: ValueAddedObjectData[]): SummaryWithTotals => {
    const nonFinanceRows = objects.filter((obj) => obj.scope !== Scope.FinanceEnabling);
    const directWages = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.direct.wages));
    const directNetIncome = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.direct.netIncome));
    const directTaxes = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.direct.taxes));
    const supplyWages = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.supply.wages));
    const supplyNetIncome = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.supply.netIncome));
    const supplyTaxes = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.supply.taxes));
    const powerWages = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.power.wages));
    const powerNetIncome = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.power.netIncome));
    const powerTaxes = sumNumbersWithUndefined(nonFinanceRows.map((obj) => obj.power.taxes));

    return {
        columns: ["wages", "netIncome", "taxes"],
        data: [
            {
                title: "direct",
                values: [directWages, directNetIncome, directTaxes],
                total: sumNumbersWithUndefined([directWages, directNetIncome, directTaxes]),
            },
            {
                title: "supplyChain",
                values: [supplyWages, supplyNetIncome, supplyTaxes],
                total: sumNumbersWithUndefined([supplyWages, supplyNetIncome, supplyTaxes]),
            },
            {
                title: "powerEnabling",
                values: [powerWages, powerNetIncome, powerTaxes],
                total: sumNumbersWithUndefined([powerWages, powerNetIncome, powerTaxes]),
            },
        ],
        columnTotals: [
            sumNumbersWithUndefined([directWages, supplyWages, powerWages]),
            sumNumbersWithUndefined([directNetIncome, supplyNetIncome, powerNetIncome]),
            sumNumbersWithUndefined([directTaxes, supplyTaxes, powerTaxes]),
        ],
        total: sumNumbersWithUndefined([
            directWages,
            supplyWages,
            powerWages,
            directNetIncome,
            supplyNetIncome,
            powerNetIncome,
            directTaxes,
            supplyTaxes,
            powerTaxes,
        ]),
    };
};

const createValueAddedSectoralExposureTableData = (objects: ValueAddedObjectData[]): SummaryWithTotals => {
    // only take objects for financeEnabling
    const financeRows = objects.filter((obj) => obj.scope === Scope.FinanceEnabling);

    const directWages = sumNumbersWithUndefined(financeRows.map((obj) => obj.direct.wages));
    const directNetIncome = sumNumbersWithUndefined(financeRows.map((obj) => obj.direct.netIncome));
    const directTaxes = sumNumbersWithUndefined(financeRows.map((obj) => obj.direct.taxes));
    const supplyWages = sumNumbersWithUndefined(financeRows.map((obj) => obj.supply.wages));
    const supplyNetIncome = sumNumbersWithUndefined(financeRows.map((obj) => obj.supply.netIncome));
    const supplyTaxes = sumNumbersWithUndefined(financeRows.map((obj) => obj.supply.taxes));

    return {
        columns: ["wages", "netIncome", "taxes"],
        data: [
            {
                title: "direct",
                values: [directWages, directNetIncome, directTaxes],
                total: sumNumbersWithUndefined([directWages, directNetIncome, directTaxes]),
            },
            {
                title: "supplyChain",
                values: [supplyWages, supplyNetIncome, supplyTaxes],
                total: sumNumbersWithUndefined([supplyWages, supplyNetIncome, supplyTaxes]),
            },
        ],
        columnTotals: [
            sumNumbersWithUndefined([directWages, supplyWages]),
            sumNumbersWithUndefined([directNetIncome, supplyNetIncome]),
            sumNumbersWithUndefined([directTaxes, supplyTaxes]),
        ],
        total: sumNumbersWithUndefined([directWages, supplyWages, directNetIncome, supplyNetIncome, directTaxes, supplyTaxes]),
    };
};

// TODO remove at some point, made category specific implementations as the data is broken down differently
export const createSummary = (
    rawData: ExcelJsonSheet,
    columns: [keyof ExcelJsonRow, string][],
    rows: [string, string][],
    filterColumn: keyof ExcelJsonRow,
): Summary => {
    const resultColumns = columns.map((cp) => cp[1]);

    const data = rows.map((rowMapping) => {
        return {
            title: rowMapping[1],
            values: columns.map((columnPair) => {
                return rawData
                    .filter((row) => row[filterColumn] === rowMapping[0])
                    .reduce((previous, current) => previous + (current[columnPair[0]] as number), 0);
            }),
        };
    });
    const columnTotals = data.reduce(
        (p, c) => {
            const result = [...p];
            for (let i = 0; i < c.values.length; ++i) {
                result[i] += c.values[i];
            }
            return result;
        },
        columns.map(() => 0),
    );
    return { data, columns: resultColumns, columnTotals };
};

export const createTableAndChartData = (objectData: ObjectData, filter: Filter): Data | null => {
    // first apply general filter, also multiply with share if committed or outstanding is selected
    const filteredData = filterData(objectData, filter);

    // big tables at the bottom
    const ghgTableData = createGhgTableData(filteredData.ghg, filter.attribution);
    const employmentTableData = createEmploymentTableData(filteredData.employment, filter.attribution);
    const valueAddedTableData = createValueAddedTableData(filteredData.valueAdded, filter.attribution);
    const overviewTableData = createOverviewTableData(filteredData.ghg, filteredData.employment, filteredData.valueAdded, filter.attribution);

    // investmentData, used by filter and overview page
    const investmentData = createInvestmentData(filteredData.general);

    // summaries
    const ghgSummary = createGhgSummary(filteredData.ghg, filter.attribution);
    const employmentSummary = createEmploymentSummary(filteredData.employment, filter.attribution);
    const valueAddedSummary = createValueAddedSummary(filteredData.valueAdded, filter.attribution);

    // chart + tables
    const ghgIndividualClientsChartData = createGhgIndividualClientsChartData(filteredData.ghg);
    const ghgSectoralExposureChartData = createGhgSectoralExposureChartData(filteredData.ghg);

    const employmentIndividualClientsTableData = createEmploymentIndividualClientsTableData(filteredData.employment);
    const employmentSectoralExposureTableData = createEmploymentSectoralExposureTableData(filteredData.employment);
    // for employment the charts are a subset of the table data
    const employmentIndividualClientsChartData = createEmploymentIndividualClientsChartData(employmentIndividualClientsTableData);
    const employmentSectoralExposureChartData = createEmploymentSectoralExposureChartData(employmentSectoralExposureTableData);

    const valueAddedIndividualClientsChartData = createValueAddedIndividualClientsTableData(filteredData.valueAdded);
    const valueAddedSectoralExposureChartData = createValueAddedSectoralExposureTableData(filteredData.valueAdded);

    return {
        ghgTableData,
        employmentTableData,
        valueAddedTableData,
        overviewTableData,

        investmentData,
        ghgSummary,
        valueAddedSummary,
        employmentSummary,

        ghgIndividualClientsChartData,
        ghgSectoralExposureChartData,

        employmentIndividualClientsTableData,
        employmentSectoralExposureTableData,
        employmentIndividualClientsChartData,
        employmentSectoralExposureChartData,

        valueAddedIndividualClientsChartData,
        valueAddedSectoralExposureChartData,
    };
};
