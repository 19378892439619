export const SubIndicator = "Sub-indicator";
export const Scope = "Scope";
export const SubScope = "Sub-scope";
export const Indicator = "Impact indicator";
export const LDC = "Least Developed Countries (LDC)";
export const Income = "Income classification";
export const Region = "Region Name";
export const SubRegion = "Sub-region Name";
export const Country = "Country name";
export const EconAct = "Economic activity";
export const EconActCode = "Economic activity - Code";
export const ReportingYear = "Reporting year";
export const FiscalYear = "Fiscal year";
export const ProjectTimeline = "ProjectTimeline";
export const Client = "Client name/code";
export const ClientType = "Client type";
export const Investee = "Investee name/code";
export const Comment = "Comment";
export const Revenue = "Revenue";
export const OutstandingAmount = "Outstanding amount";
export const PowerProduction = "Power Production";
export const AttributedTotal = "Attributed total - Outstanding";
export const Total = "Total";
export const YouthEmployment = "Youth employment share";
export const ImpactOutstanding = "Impact / mUSD outstanding amount";
export const ImpactRevenue = "Impact / mUSD revenue";

export const SectorAgriculture = "Agriculture, forestry and fishing";
export const SectorMining = "Mining and quarrying";
export const SectorManufacturing = "Manufacturing";
export const SectorUtilities = "Utilities";
export const SectorConstruction = "Construction";
export const SectorVehicles = "Wholesale and retail trade; repair of motor vehicles and motorcycles";
export const SectorAccomodation = "Accommodation and food service activities";
export const SectorTransport = "Transport; storage and communication";
export const SectorFinancial = "Financial and insurance activities";
export const SectorBusiness = "Real estate; business and administrative activities";
export const SectorOther = "Other services";
export const SectorPublic = "Public administration and defence; compulsory social security";
export const SectorEducation = "Education";
export const SectorSocial = "Human health and social work activities";

export const AttributionOutstanding = "Attribution - Outstanding amount";
export const AttributionShareCommitted = "Attribution share - Committed";
export const AttributionShareOutstanding = "Attribution share - Outstanding";

export const Strings = [
    Indicator,
    SubIndicator,
    Scope,
    SubScope,
    LDC,
    Income,
    Region,
    SubRegion,
    Country,
    EconAct,
    EconActCode,
    Client,
    ClientType,
    Investee,
    Comment,
];

export const Numeric = [
    ReportingYear,
    FiscalYear,
    ProjectTimeline,
    Revenue,
    OutstandingAmount,
    PowerProduction,
    AttributedTotal,
    Total,
    YouthEmployment,
    ImpactOutstanding,
    ImpactRevenue,
    SectorAgriculture,
    SectorMining,
    SectorManufacturing,
    SectorUtilities,
    SectorConstruction,
    SectorVehicles,
    SectorAccomodation,
    SectorTransport,
    SectorFinancial,
    SectorBusiness,
    SectorOther,
    SectorPublic,
    SectorEducation,
    SectorSocial,
    AttributionOutstanding,
    AttributionShareCommitted,
    AttributionShareOutstanding,
];

export const ALL = [...Strings, ...Numeric];
